import { CHECK_IS_CANARY } from "./flag"

export const ENV = process.env.NEXT_PUBLIC_ENV || "development"

export const IS_PREPRODUCTION = ENV === "preproduction"
export const IS_STAGING = ENV === "staging"
export const IS_DEVELOPMENT = ENV === "development"
export const IS_CANARY = CHECK_IS_CANARY()
export const IS_CANARY_PREPROD = IS_CANARY || IS_PREPRODUCTION
export const IS_PRODUCTION = ENV === "production" || IS_PREPRODUCTION

export const LIST_ERROR_KEYWORD = [
  "errors",
  "error",
  "GenericError",
  "reqId",
  "Error",
  "Errors",
]

export * from "./api"
export * from "./banks"
export * from "./payments"
export * from "./zendeskURL"
export * from "./product"
export * from "./flag"
